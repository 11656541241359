.numeric-input-button {
  font-size: large;
  padding-left: 20px;
  padding-right: 20px;
  /*
  This stuff prevents highlighting of the input group text,
  was a problem on mobile devices as clicking + or - multiple times would highlight the text
  rather than increment.
  */
  -webkit-touch-callout:none;
  -webkit-user-select:none;
  -khtml-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none;
  -webkit-tap-highlight-color:rgba(0,0,0,0);
}

.verb-badge {
  margin: 2px;
  color: white;
  background-color: green;
}

.noun-badge {
  margin: 2px;
  color: white;
  background-color: red;
}

.adjective-badge {
  margin: 2px;
  color: white;
  background-color: blueviolet;
}

.adverb-badge {
  margin: 2px;
  color: white;
  background-color: coral;
}

.conjunction-badge {
  margin: 2px;
  color: white;
  background-color: lime;
}

.badge-highlight {
  color: yellow;
  border: 4px solid black
}

.control-label {
  font-size: 25px;
}

.margin-bottom-md {
  margin-bottom: 15px;
}

.margin-bottom-xs {
  margin-bottom: 5px;
}

.margin-bottom-0 {
  margin-bottom: 0;
}

/* Hide input arrows webkit */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* ...Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

@media (max-width: 768px) {
  .container {
     width: 100%;
  }
}

@media (min-width: 768px) {
  .container {
     width: 768px;
  }
}

.word-length-input-group {
  min-width: 150px;
}